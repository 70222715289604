import "./src/scss/main.scss";

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage && typeof window !== "undefined") {
   if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
         window.matchMedia("(prefers-color-scheme: dark)").matches)
   ) {
      document.documentElement.classList.add("dark");
   } else {
      document.documentElement.classList.remove("dark");
   }
}
